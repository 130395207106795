import Button from 'src/components/Button';
import Heading from 'src/components/Heading';
import Image from 'src/components/Image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';
import { Link } from 'gatsby';
import { above, below } from 'src/components/global/mediaqueries';

const Wrapper = styled('div')`
    width: 100%;

    ${below.tablet_s} {
        ${theme.spacing.mobile._40('padding-bottom')};
    }
    ${above.tablet_s} {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
`;

const HeroImageContainer = styled('div')`
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    ${below.tablet_s} {
        padding-left: 12.5vw;
        padding-right: 12.5vw;
        padding-top: 12.5vw;
        ${theme.spacing.mobile._32('padding-bottom')};
    }

    ${theme.spacing.tablet._32('margin-top')};
    ${theme.spacing.tablet._32('margin-bottom')};
    ${theme.spacing.tablet._32('margin-left')};
    ${theme.spacing.desktop._64('margin-top')};
    ${theme.spacing.desktop._64('margin-bottom')};
    ${theme.spacing.desktop._64('margin-left')};

    ${above.tablet_s} {
        width: 33vw;
    }
`;

const OuterImageWrapper = styled('div')`
    position: relative;
    width: 100%;
    padding-bottom: 140%;
`;

const ImageWrapper = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const HeroTextWrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ${theme.spacing.tablet._32('padding-top')};
    ${theme.spacing.tablet._32('padding-bottom')};
    ${theme.spacing.desktop._64('padding-top')};
    ${theme.spacing.desktop._64('padding-bottom')};

    ${above.tablet_s} {
        height: 100%;
        width: 66vw;
    }
`;

const HeroHeading = styled(Heading)`
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 125%;

    ${above.desktop_s} {
        line-height: 115%;
    }

    ${theme.fontSize.sans.mobile._32};
    ${theme.fontSize.sans.tablet._32};
    ${theme.fontSize.sans.desktop._64};

    ${theme.spacing.mobile._16('margin-bottom')};
    ${theme.spacing.tablet._16('margin-bottom')};
    ${theme.spacing.desktop._24('margin-bottom')};
`;

const HeroText = styled(Text)`
    line-height: 160%;
    text-align: center;
    width: 75vw;

    ${above.tablet_s} {
        width: 50vw;
    }

    ${above.desktop_s} {
        width: 42vw;
        max-width: 40em;
    }

    ${theme.spacing.mobile._24('margin-bottom')};
    ${theme.spacing.tablet._24('margin-bottom')};
    ${theme.spacing.desktop._32('margin-bottom')};
`;

const HeroProduct = ({ data }) => {
    const { backgroundColor, heading, productLink, product, text } = data;
    const heroBackgroundColor = theme.color[backgroundColor] || theme.color.white;
    const heroTextColor = theme.backgroundTextColor[backgroundColor] || theme.color.black;
    const heroButtonColor = theme.backgroundButtonColor[backgroundColor] || theme.color.black;

    return (
        <Wrapper style={{ backgroundColor: heroBackgroundColor }}>
            <HeroImageContainer>
                <OuterImageWrapper>
                    <ImageWrapper>
                        <Image
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            objectFit="cover"
                            fluid={product.image.src.large}
                        />
                    </ImageWrapper>
                </OuterImageWrapper>
            </HeroImageContainer>
            <HeroTextWrapper>
                <HeroHeading as="h1" style={{ color: heroTextColor }}>{heading}</HeroHeading>
                <HeroText style={{ color: heroTextColor }}>{text}</HeroText>
                <Link to={product.acfData.permalink}>
                    <Button buttonColor={heroButtonColor}>{productLink.text}</Button>
                </Link>
            </HeroTextWrapper>
        </Wrapper>
    );
};

export default HeroProduct;

HeroProduct.propTypes = {
    data: PropTypes.shape({
        backgroundColor: PropTypes.string,
        heading: PropTypes.string,
        product: PropTypes.object.isRequired,
        productLink: PropTypes.shape({
            text: PropTypes.string,
        }),
        text: PropTypes.string,
    }),
};

HeroProduct.defaultProps = {
    data: {
        heading: '',
        product: {},
        productLink: PropTypes.shape({
            text: '',
        }),
        text: '',
    },
};
